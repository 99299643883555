import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Play } from "../../images/icons/play";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import Container from "react-bootstrap/Container";
// @ts-ignore
import { Player, ControlBar, ClosedCaptionButton } from "video-react";
import {
  ImageInterface,
  PortableText,
  SanityCtaLabelsInterface,
  SanityTrainingVideoInterface
} from "../../types/SanityTypes";
import ModalBlock, { ModalBlockInterface } from "../../components/Modal";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { event41 } from "../../analytics/event41";
import { event42 } from "../../analytics/event42";
import { event54 } from "../../analytics/event54";
import { event53 } from "../../analytics/event53";
import { event52 } from "../../analytics/event52";

export type TrainingVideoInterface = SanityTrainingVideoInterface & SanityCtaLabelsInterface;

const TrainingVideoBlock: FunctionComponent<TrainingVideoInterface> = ({
  title,
  videoAsset,
  pdfReference,
  ctaLabel,
  downloadable,
  fileDownloadReference,
  surveyModal,
  ctaLabels
}) => {
  const videoCoverImage = (videoAsset._rawCoverImage || videoAsset.coverImage) as ImageInterface;
  const videoText = (videoAsset._rawText || videoAsset.text) as PortableText;
  const videoAssetId = (videoAsset.video.asset._id || videoAsset.video.asset._ref) as string;
  const videoColorTheme = videoAsset.colorlist.value || "var(--primary-color)";
  const fileDownloadReferenceObj = fileDownloadReference;

  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const [imageDisplay, setImageDisplay] = useState({ visibility: "visible" } as React.CSSProperties);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const player = useRef(null);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    let previousPosition = 0;
    const onPlay = () => {
      event41(title, videoAssetId);
      // If the user pauses, we don't want to track subsequent play events
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
    };
    const onEnded = () => {
      event42(title, videoAssetId);
      // If the user watches the video again, track it
      (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    };
    const onTimeUpdate = () => {
      let currentPosition = 0;
      if ((player?.current as any)?.video?.video?.currentTime && (player?.current as any)?.video?.video?.duration) {
        currentPosition = (player?.current as any)?.video?.video?.currentTime / (player?.current as any)?.video?.video?.duration;
      }
      if (currentPosition >= 0.75 && previousPosition < 0.75) {
        event54(title, videoAssetId);
      }
      if (currentPosition >= 0.5 && previousPosition < 0.5) {
        event53(title, videoAssetId);
      }
      if (currentPosition >= 0.25 && previousPosition < 0.25) {
        event52(title, videoAssetId);
      }
      previousPosition = currentPosition;
    };

    (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    (player?.current as any)?.video?.video?.addEventListener("ended", onEnded);
    (player?.current as any)?.video?.video?.addEventListener("timeupdate", onTimeUpdate);

    return () => {
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
      (player?.current as any)?.video?.video?.removeEventListener("ended", onEnded);
      (player?.current as any)?.video?.video?.removeEventListener("timeupdate", onTimeUpdate);
    };
  }, [title, videoAssetId]);

  const toggleVideo = () => {
    setImageDisplay({ visibility: "hidden" });
   ( player?.current as any)?.play();
    setVideoPlaying(true);
  };

  const renderCoverImage = (coverImage: ImageInterface, renderType: string) => {
    return (
      <picture
        className="placeholder"
        style={
          coverImage.asset.metadata
            ? {
                background: `url(${coverImage.asset.metadata.lqip})`,
                backgroundSize: "cover"
              }
            : undefined
        }
      >
        <source
          media={"(min-width: 1200px)"}
          srcSet={
            urlBuilder.image(coverImage).auto("format").quality(80).width(1350).height(780).format("webp").url() ||
            undefined
          }
        />
        <source
          media={"(min-width: 768px)"}
          srcSet={
            urlBuilder.image(coverImage).auto("format").quality(80).width(800).height(460).format("webp").url() ||
            undefined
          }
        />
        <img
          src={
            urlBuilder.image(coverImage).auto("format").quality(80).width(700).height(400).format("webp").url() ||
            undefined
          }
          alt={renderType}
          loading={"lazy"}
          className="landscape-cover-image"
          data-testid="video-cover-image"
        />
        <div className="landscape-play-icon" data-testid="play-button">
          <Play />
        </div>
      </picture>
    );
  };

  const renderLandscapeVideoAndImage = (containerClass: string, videoContent: string) => {
    return (
      <>
        <button onClick={() => toggleVideo()} className={videoContent} style={imageDisplay}>
          {renderCoverImage(videoCoverImage, videoCoverImage.alt)}
          <span className="sr-only sr-only-focusable">Play {videoCoverImage.alt}</span>
        </button>
        {renderVideo(containerClass, videoContent)}
      </>
    );
  };
  const renderVideo = (containerClass: string, videoContent: string) => {
    return (
      <div className={containerClass} hidden={!videoPlaying}>
        <div className="landscape-video">
          <Player ref={player} preload={"metadata"}>
            <source src={videoAsset.video.asset.url} type="video/mp4" />
            <ControlBar autoHide={false}>
              <ClosedCaptionButton order={7} />
            </ControlBar>
          </Player>
        </div>
      </div>
    );
  };

  if (
    videoAsset.videoType.name === "Landscape-left" ||
    videoAsset.videoType._ref === "f3d4289f-99a5-4ee2-9dfc-dbe69cadfb41"
  ) {
    return (
      <div className="training-video-block">
        <Container fluid>
          <Row>
            <h2 style={{ color: videoColorTheme }} className="left-header">
              {title}
            </h2>
          </Row>
          <Row className="no-gutters" data-testid="landscape-left">
            <Col lg={8} md={12} className="left-landscape-vid-top-margin" style={{ height: "fit-content" }}>
              {renderLandscapeVideoAndImage("rx-video--video left-video", "rx-video--poster left-landscape-image")}
            </Col>
            <Col lg={4} md={12}>
              <div style={{ borderColor: videoColorTheme }} className="text-box text-box-right">
                <BlockContent blocks={videoText} />
                <div>
                  <div
                    className={
                      fileDownloadReference && fileDownloadReferenceObj?.downloadable2 ? "video-landscape-btns" : ""
                    }
                  >
                    {downloadable && pdfReference && (
                      <div>
                        <a
                          href={pdfReference?.asset?.asset.url}
                          className="btn-primary"
                          download
                          data-testid="pdf-download-button"
                          data-analytics-event49=""
                        >
                          {ctaLabel}
                        </a>
                      </div>
                    )}
                    {fileDownloadReferenceObj?.downloadable2 && fileDownloadReference && (
                      <div>
                        <a
                          href={fileDownloadReference?.assetLink}
                          className="btn-primary"
                          download
                          data-testid="pdf-download-button"
                          data-analytics-event49=""
                        >
                          {fileDownloadReferenceObj?.ctaLabelFile}
                        </a>
                      </div>
                    )}
                  </div>
                  {surveyModal && (
                    <div className="survey-modal-button">
                      <button
                        className="btn-primary"
                        onClick={handleShow}
                        id={`trainingVideoBlockLink-${videoAssetId}`}
                        style={{ marginTop: "20px" }}
                      >
                        {ctaLabels?.videoFeedback || "Complete module survey"}
                      </button>
                      <ModalBlock
                        data={surveyModal as ModalBlockInterface["data"]}
                        showModal={showModal}
                        handleClose={handleClose}
                        target={videoAssetId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (
    videoAsset.videoType.name === "Landscape-right" ||
    videoAsset.videoType._ref === "ae2e49d3-3246-4cc7-8c04-3365a22a373b"
  ) {
    return (
      <div className="training-video-block">
        <Container fluid>
          <Row>
            <h2 style={{ color: videoColorTheme }} className="right-header">
              {title}
            </h2>
          </Row>
          <Row className="no-gutters d-flex" data-testid="landscape-right">
            <Col lg={8} md={12} className="order-lg-2" style={{ height: "fit-content" }}>
              {renderLandscapeVideoAndImage("rx-video--video right-video", "rx-video--poster right-landscape-image")}
            </Col>
            <Col lg={4} md={12}>
              <div style={{ borderColor: videoColorTheme }} className="text-box text-box-left">
                <BlockContent blocks={videoText} />
                <div>
                  <div
                    className={
                      fileDownloadReference && fileDownloadReferenceObj?.downloadable2 ? "video-landscape-btns" : ""
                    }
                  >
                    {downloadable && pdfReference && (
                      <div>
                        <a
                          href={pdfReference?.asset?.asset.url}
                          className="btn-primary"
                          download
                          data-testid="pdf-download-button"
                          data-analytics-event49=""
                        >
                          {ctaLabel}
                        </a>
                      </div>
                    )}
                    {fileDownloadReferenceObj?.downloadable2 && fileDownloadReference && (
                      <div>
                        <a
                          href={fileDownloadReference?.assetLink}
                          className="btn-primary"
                          download
                          data-testid="pdf-download-button"
                          data-analytics-event49=""
                        >
                          {fileDownloadReferenceObj?.ctaLabelFile}
                        </a>
                      </div>
                    )}
                  </div>
                  {surveyModal && (
                    <div className="survey-modal-button">
                      <button
                        className="btn-primary"
                        onClick={handleShow}
                        id={`trainingVideoBlockLink-${videoAssetId}`}
                        style={{ marginTop: "20px" }}
                      >
                        {ctaLabels?.videoFeedback || "Complete module survey"}
                      </button>
                      <ModalBlock
                        data={surveyModal as ModalBlockInterface["data"]}
                        showModal={showModal}
                        handleClose={handleClose}
                        target={videoAssetId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return null;
};

export default TrainingVideoBlock;
